$primary-color: #f3a131;

@keyframes intro {

  0%,
  100% {
    opacity: 1;

  }

  50% {
    opacity: 0.5;
  }
}

.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: $primary-color;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &-inner {
    position: absolute;
    animation: intro 1s infinite;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}